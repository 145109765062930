import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { transparentize, readableColor } from 'polished'
import styled from 'styled-components'
import { config, useSpring, animated } from 'react-spring'
import Layout from '../components/layout'
import { Box, AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import { parseImageUrl } from '@conradlin/notabase/src/utils'
import PostItem from "../components/postItem"
import { colors } from '../styles/colors';
import Signup from "../components/signup";
import { cssNumber, data } from 'cypress/types/jquery'

import { MDXRenderer } from 'gatsby-plugin-mdx';

const Mark1 = styled.mark`
background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #D9EFFC 50%);
color: black;
:hover {
  color: #2592AE;
}
`

const MarkdownWrapper = styled.div`
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: .375rem;
  }
  pre { 
    white-space: pre-wrap;
    background-color: #27272a;
    border-radius: .375rem;
    color: #e4e4e7;
    font-size: .875em;
    line-height: 1.7142857;
    margin-bottom: 1.7142857em;
    margin-top: 1.7142857em;
    overflow-x: auto;
    padding: .8571429em 1.1428571em; 
  }
  code {
    color: #f05f70;
  }
  blockquote {
    background: lightgray;
    border-left:5px solid black;
    margin: 20px;
    padding: 20px;
  }
  hr { 
    margin: 25px 0 25px 0; 
  }
 …
`;

const PostCardStyles = styled(AnimatedBox)`
  position: relative;
  text-align: center;
  overflow: hidden;
  margin: 0 0 40px;
  height: 35vh;
  border-radius: 5px;
  text-align: center;
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
  
`;

const PostCardImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  align-items: center;
`;

const PostFeed = styled(AnimatedBox)` 
position: relative;
display: flex;
flex-wrap: wrap;
`

const PBoxBody = styled(AnimatedBox)`
max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 0;
`
const PBoxBottom = styled(AnimatedBox)`
max-width: 1400px;
  margin: 0 auto;
  padding-top: 0;
`

const Content = styled(animated.div)`
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
`
const FeedContent = styled(animated.div)`
`
const TextCont = styled.div`
position: absolute;
top: 50%;
left: 50%;
margin-right: -25%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
`

const Category = styled(AnimatedBox)`
  letter-spacing: 0.05em;
  font-size: ${props => props.theme.fontSizes[1]};
  text-transform: uppercase;
  color: white;
`

const PublishedDate = styled(AnimatedBox)`
  letter-spacing: 0.05em;
  font-size: ${props => props.theme.fontSizes[0]};
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  line-height: 33px;
`

const Title = styled(animated.h1)`
  color: white;
  font-size: 4vh;
`
const BlogImg = styled(Img)`
  filter: brightness(25%); 
  -webkit-filter: brightness(25%); 
`

const Description = styled(animated.div)`
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  margin-bottom: ${props => props.theme.space[5]};
`

const PButton = styled(Button) <{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
`

type PageProps = {
  data: {
    mdx: {
      frontmatter: {
        title: string
        tags: {
          name: string
        }
        publish_date: {
          start: string
        }
        url: string
        desc: string
        color: string
        cover_image: string
      }
      body: string
    }
  }

}


const blogPost: React.FunctionComponent<PageProps> = ({ pageContext, data: { mdx, allNotion } }) => {
  const categoryAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const imagesAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

  // const mdx = data.mdx!;
  const body = mdx.body;

  let disqusConfig = {
    url: `https://conradlin.com/blog/posts/${mdx.frontmatter?.url}`,
    identifier: mdx.frontmatter?.url,
    title: mdx.frontmatter?.title,
  }

  let coverimageURL = pageContext.coverimageURL;
  let tags = pageContext.tags;


  return (
    <Layout color={mdx.frontmatter?.color}>
      <SEO
        pathname={`/blog/posts/${mdx.frontmatter?.url}`}
        title={`${mdx.frontmatter?.title} | Conrad`}
        desc={mdx.frontmatter?.desc}
        postBanner={coverimageURL.src}
      //banner={project.cover.childImageSharp.resize.src}
      //individual
      />
      <PBoxBody py={10} px={[6, 6, 8, 10]}>
        {/*<Description style={descAnimation}>
          <div dangerouslySetInnerHTML={{ __html: posts.desc }} />
         </Description>*/}

        <PostCardStyles className={`post-card ${coverimageURL ? '' : 'no-image'}`}>
          <PostCardImage className="post-card-image">
            <BlogImg className="post-card-img"
              alt={`${mdx.frontmatter?.title} cover image`}
              style={{ width: '100%', height: '100%' }}
              fluid={coverimageURL}
            />
          </PostCardImage>
          <TextCont>
            <Category style={categoryAnimation}>{mdx.frontmatter?.tags.name}</Category>
            <Title style={titleAnimation}>{mdx.frontmatter?.title}</Title>
            <PublishedDate style={titleAnimation}>📅 {mdx.frontmatter?.publish_date.start}</PublishedDate>
          </TextCont>
        </PostCardStyles>
        <AnimatedBox pb={[20]} px={[0, 6, 8, 11]}>
          <Content style={descAnimation}>
            <MarkdownWrapper>
              <MDXRenderer>{body}</MDXRenderer>
            </MarkdownWrapper>
            {/* <div dangerouslySetInnerHTML={{ __html: posts.html }} /> */}
            {/*}
          <p style={{ textAlign: 'center' }}>//</p>
          <p><em>This article was written on <a href="https://conradl.in/notion" target="_blank">Notion</a>, which is currently <a href="https://conradlin.com/blog/posts/host-gatsbyjs-blog-with-notion-cms-and-netlify-for-free/" target="_blank">powering this site as a CMS</a>. As of Jun 7. 2020, Notion seems to be rate limiting Netlify's IP causing some articles to display 'fetch error' or have partial content missing. We expect such errors to be resolved soon, but in such cases, you can read the full article at <a href={`https://blog.conradlin.com/${posts.slug}`} target="_blank">blog.conradlin.com</a>.</em></p>
        */}
          </Content>
        </AnimatedBox>
      </PBoxBody>
      <PBoxBottom style={{ textAlign: 'center' }} pb={[8]} px={[6, 6, 8, 10]}>
        <Content style={descAnimation}>

          <h2>Did this resonate with you?</h2>
          {/*<PButton color={posts.color} py={4} px={8}>
          Share with a friend
        </PButton>
        */}
          <div className="iframe-container-full">
            <Disqus config={disqusConfig} />
          </div>
        </Content>

      </PBoxBottom>
      <PBoxBottom py={6} px={[0, 6, 8, 10]}>
        <FeedContent>
          <h2 style={{ textAlign: 'center' }} >My Latest <Mark1>{tags}s</Mark1></h2>
          <PostFeed>
            {
              allNotion.edges.map(e => <PostItem data={e} />)
            }
          </PostFeed>

        </FeedContent>
        <div style={{ textAlign: 'center' }}>
          <Link to="/blog">
            <PButton color="white" py={4} px={8} mb={8}>
              See All Posts
            </PButton>
          </Link>
        </div>
      </PBoxBottom>
    </Layout>
  )



  // return (

  //         <MDXRenderer>{body}</MDXRenderer>

  // )
}

export default blogPost

export const query = graphql`
query($id: String!, $tags: [String]) {
    mdx(id: { eq: $id }) {
      frontmatter {
          title
          tags {
            name
          }
          publish_date {
            start(fromNow: false, formatString: "YYYY-MMM-DD")
          }
          url
          desc
          color
        }
        body
      }
      allNotion(
        filter: {properties: {status: {value: {name: {eq: "published"}}}}, childMdx: {id: {ne: $id}, frontmatter: {tags: {elemMatch: {name: {in: $tags}}}}}}
        limit: 2
        sort: {fields: childMdx___frontmatter___publish_date___start, order: DESC}
      ) {
        edges {
          node {
            localImage {
              childImageSharp {
                fluid(quality: 95, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            childMdx {
              frontmatter {
                title
                tags {
                  name
                }
                content_type {
                  name
                }
                desc
                status {
                  name
                }
                url
                read_time
                publish_date {
                  start(fromNow: false, formatString: "YYYY-MMM-DD")
                }
              }
              timeToRead
            }
          }
        }
  }
}
`