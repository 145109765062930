import React from "react"
import { Link } from "gatsby";
import styled from 'styled-components'
import { Box, Flex } from '../elements'
import { colors } from '../styles/colors';
import { parseImageUrl } from '@conradlin/notabase/src/utils'
import Img from 'gatsby-image';

const CardLink = styled(Link)`
    text-decoration: none;
    flex-grow: 1;
    display: block;
    padding: 25px 25px 0px;
    position: relative;
    
  color: ${colors.darkgrey};

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const PostCardStyles = styled.article`
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;
  
  :hover {
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
  }
`;

const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 85%;
`;

const PostCardTags = styled.span`
  display: block;
  margin-bottom: 4px;
  color: ${colors.midgrey};
  font-size: .8rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;


const ReadingTime = styled.span`
  flex-shrink: 0;
  color: ${colors.midgrey};
  font-size: .8rem;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  align-content: flex-end;
`;

const PublishedDate = styled.span`
  flex-shrink: 0;
  color: ${colors.midgrey};
  font-size: .8rem;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const PostCardTitle = styled.h4`
  margin-top: 0px;
  margin-bottom: 0px;
  color: black;
`
const PostCardMeta = styled.footer`
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  padding: 0 25px 25px;
`;


const PostCardImage = styled.div`
  width: auto;
  height: 200px;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  align-items: center;
`;

const PostCardImageLink = styled(Link)`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

export default ({ data }) => {
  const frontmatter = data.node.childMdx.frontmatter;
  const { title, tags, publish_date, desc, read_time, url } = frontmatter;
  let coverimageURL = data.node.localImage.childImageSharp.fluid;

  return (
    <PostCardStyles className={`post-card ${coverimageURL ? '' : 'no-image'}`}>
      <PostCardImageLink className="post-card-image-link" to={`/blog/posts/${url}/`}>
        <PostCardImage className="post-card-image">
          <Img className="post-card-img"
            alt={`${title} cover image`}
            style={{ width: '100%', height: '100%' }}
            fluid={coverimageURL}
          />
        </PostCardImage>
      </PostCardImageLink>

      <PostCardContent className="post-card-content">
        <CardLink className="post-card-content-link" to={`/blog/posts/${url}/`}>
          <header className="post-card-header">
            <PostCardTags>{tags.map(e => e.name).join(' • ')}</PostCardTags>
            <PostCardTitle> {title}</PostCardTitle>
          </header>
          <p style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: desc }}></p>

        </CardLink>
        <PostCardMeta className="post-card-meta">
          <PublishedDate>📅 {publish_date.start}</PublishedDate>
          <ReadingTime>⏱ {data.node.childMdx.timeToRead} MIN READ</ReadingTime>
        </PostCardMeta>
      </PostCardContent>
    </PostCardStyles>
  )
}